<script>

export default {
  props: {

  },
  components: {

  },
  data() {
    return {
      openDialog: false,
      user: null,
      password: '',
      passwordConfirm: ''
    };
  },
  mounted() {

  },
  methods: {
    open: function(user) {
      this.user = user
      this.openDialog = true
    },
    process: async function() {
      const loader = this.$loading.show({ container: this.$refs.modifyContainer })
      try {
        const result = await this.$API.user.modifyInfo(this.user.id,{
          password: this.password,
          passwordConfirm: this.passwordConfirm,
          language: this.user.language,
          currency: this.user.currency,
          timezone: this.user.timezone
        })
        if (result.code !== 0) {
          return alert(result.msg)
        }
        if (result.token) {
          this.$store.commit('auth/SET_TOKEN', result.token)
        }
        this.openDialog = false
      } catch(e) {
        this.$log.error(e)
      } finally {
        loader.hide()
      }
    }
  }
};
</script>

<template>
  <div ref="modifyContainer">
    <b-modal
        title="정보 수정"
        v-model="openDialog"
        scrollable
        centered
        button-size="sm"
        hide-footer
    >
      <div class="row" v-if="user">
        <div class="col-md-12">
          <form @submit.prevent="process">
            <div class="text-info mb-1 small">* {{ $t('text.api.username_condition_desc') }}</div>
            <div class="form-floating mb-3">
              <input type="text" class="form-control" disabled :placeholder="$t('로그인 아이디')" :value="user.username" />
              <label class="form-label">{{ $t('로그인 아이디') }}</label>
            </div>

            <div class="form-floating mb-3">
              <input type="password" class="form-control" :placeholder="$t('신규 비밀번호')" v-model="password" />
              <label class="form-label">{{ $t('비밀번호') }}</label>
            </div>
            <div class="form-floating mb-3">
              <input type="password" class="form-control" :placeholder="$t('신규 비밀번호 확인')" v-model="passwordConfirm" />
              <label class="form-label">{{ $t('비밀번호 확인') }}</label>
            </div>
            <div class="text-info mb-1 small">* {{ $t('text.api.nickname_condition_desc') }}</div>
            <div class="form-floating mb-3">
              <input type="text" class="form-control" :value="user.nickname" disabled :placeholder="$t('별칭')" />
              <label class="form-label">{{ $t('별칭') }}</label>
            </div>
            <div class="form-group mb-3">
              <div class="input-group">
                <input type="text" class="form-control text-end" disabled :placeholder="$t('요율')" :value="user.cashRate" />
                <span class="input-group-text">%</span>
              </div>
            </div>
            <div class="text-info mb-1 small">* {{ $t('text.register_email_desc') }}</div>
            <div class="form-floating mb-3">
              <input type="text" class="form-control" :placeholder="$t('이메일')" disabled :value="user.email" />
              <label class="form-label">{{ $t('이메일') }}</label>
            </div>
            <div class="form-floating mb-3">
              <select class="form-select" :placeholder="$t('언어')" v-model="user.language">
                <option value="ko">한국어</option>
                <option value="en">English</option>
              </select>
              <label class="form-label">{{ $t('언어') }}</label>
            </div>
            <div class="form-floating mb-3">
              <select class="form-select" :placeholder="$t('통화')" v-model="user.currency" disabled>
                <option value="KRW">KRW</option>
                <option value="USD">USD</option>
              </select>
              <label class="form-label">{{ $t('통화') }}</label>
            </div>
            <div class="form-floating mb-3">
              <input type="number" class="form-control" :placeholder="`${$t('타임존')}`" v-model="user.timezone" />
              <label class="form-label">{{ `${$t('타임존')}` }}</label>
            </div>
            <div class="text-end">
              <hr />
              <button type='submit' class='btn btn-primary me-1'>{{ $t('수정') }}</button>
              <button type='button' class='btn btn-secondary' data-bs-dismiss='modal'>{{ $t('닫기') }}</button>
            </div>
          </form>
        </div>
      </div>
    </b-modal>
  </div>
</template>
