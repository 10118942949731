<script>
export default {
  components: {},
  props: {
    title: {
      type: String,
      default: '',
    },
    search: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      offset: 300
    }
  },
  methods: {
    resizeStickyTableHeight: function(obj, offset) {
      let h = (window.innerHeight - offset).toString() + 'px'
      obj.style.height = h
    }
  },
  mounted() {
    const self = this
    let obj = document.getElementById('table-wrapper')
    if (obj) {
      self.$nextTick(() => {
        window.addEventListener('resize', () => {
          self.resizeStickyTableHeight(obj, self.offset)
        })
      })

      this.resizeStickyTableHeight(obj, self.offset)
      setTimeout(function() {
        self.resizeStickyTableHeight(obj, self.offset)
      }, 500)
    }
  }
}
</script>

<template>
  <div class="alert alert-danger text-center" v-show="this.$store.getters['auth/validateErrorCount'] > 0">{{ this.$store.getters['auth/validateErrorCount'] + '번째 인증 정보 요청이 실패하였습니다. 5번 실패하면 자동 로그아웃이 됩니다.'}}</div>
  <!-- start page title -->
  <div class="row">
    <div class="col-12">
      <div class="page-title-box d-flex align-items-center justify-content-between">
        <h2 class="mb-0 font-size-18">
          <span style="display: inline-block; width: 5px; background-color: #aaa;">&nbsp;</span>
          <span style="display: inline-block; width: 4px; margin-left: 1px; background-color: #bbb;">&nbsp;</span>
          <span style="display: inline-block; width: 3px; margin-left: 1px; background-color: #ccc;">&nbsp;</span>
          <span style="display: inline-block; width: 2px; margin-left: 1px; background-color: #ddd;">&nbsp;</span>
          <span style="display: inline-block; width: 1px; margin-left: 1px; margin-right: 5px; background-color: #eee;">&nbsp;</span>
          <span v-html="title"></span>
          <span style="display: inline-block; width: 1px; margin-left: 5px; background-color: #eee;">&nbsp;</span>
          <span style="display: inline-block; width: 2px; margin-left: 1px; background-color: #ddd;">&nbsp;</span>
          <span style="display: inline-block; width: 3px; margin-left: 1px; background-color: #ccc;">&nbsp;</span>
          <span style="display: inline-block; width: 4px; margin-left: 1px; background-color: #bbb;">&nbsp;</span>
          <span style="display: inline-block; width: 5px; margin-left: 1px; background-color: #aaa;">&nbsp;</span>
        </h2>

        <div class="page-title-right" v-if="search === true">
          <button class="btn btn-primary" data-bs-toggle="offcanvas" data-bs-target="#search-box" aria-controls="search-box"><span class="fa fa-search"></span></button>
        </div>
      </div>
    </div>
  </div>
  <!-- end page title -->
</template>
