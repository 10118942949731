export const menuItems = [
    {
        id: 100,
        label: "menuitems.menu.text",
        isTitle: true
    },
    {
        id: 200,
        label: "menuitems.dashboard.text", // 데쉬보드 전체현황
        icon: "",
        link: "/"
    },
    {
        id: 820,
        label: "menuitems.transactionMyMoney.text",
        icon: "",
        link: '/transaction/my/money'
    },
    /*
    {
        id: 10000,
        label: "menuitems.admins.text",
        isTitle: true,
        onlyAdmin: true,
    },
    {
        id: 600,
        label: "menuitems.admin.text",
        icon: "",
        onlyAdmin: true,
        subItems: [
            {
                id: 601,
                label: "menuitems.admin.list.company",
                link: "/admin/level/company",
                parentId: 600,
                checkRole: true,
                roles: ['admin'],
                onlyAdmin: true
            },
            {
                id: 602,
                label: "menuitems.admin.list.admin",
                link: "/admin/level/admin",
                parentId: 600,
                checkRole: true,
                roles: ['admin'],
                onlyAdmin: true
            },
            {
                id: 603,
                label: "menuitems.admin.list.helpdesk",
                link: "/admin/level/helpdesk",
                parentId: 600,
                checkRole: true,
                roles: ['admin'],
                onlyAdmin: true
            }
        ]
    },
    */
    /*
    {
        id: 90,
        label: "운영관리메뉴", // users
        isTitle: true
    },
    {
        id: 850,
        label: "menuitems.transactionAdminMoney.text",
        icon: "",
        link: '/transaction/admin/money',
        checkRole: true,
        roles: ['admin'],
        onlyAdmin: true
    },

     */
    {
        id: 860,
        label: "menuitems.admin.list.company",
        icon: "",
        link: '/admin/level/company',
        onlyAdmin: true,
    },
    {
        id: 1100,
        label: "menuitems.partners.text", // users
        isTitle: true
    },
    {
        id: 500,
        label: "menuitems.partner.text", // 파트너 관리
        icon: "",
        subItems: [

            {
                id: 501,
                label: "menuitems.partner.list.tree",
                link: "/partner/tree",
                parentId: 500
            },


            {
                id: 502,
                label: "menuitems.partner.list.top",
                link: "/partner/level/top",
                parentId: 500,
                checkRole: true,
                roles: ['admin'],
                onlyAdmin: true
            },
            {
                id: 503,
                label: "menuitems.partner.list.super",
                link: "/partner/level/super",
                parentId: 500,
                checkRole: true,
                roles: ['top']
            },
            {
                id: 504,
                label: "menuitems.partner.list.master",
                link: "/partner/level/master",
                parentId: 500,
                checkRole: true,
                roles: ['top', 'super']
            },
            {
                id: 505,
                label: "menuitems.partner.list.agent",
                link: "/partner/level/agent",
                parentId: 500,
                checkRole: true,
                roles: ['top', 'super', 'master']
            },
            {
                id: 506,
                label: "menuitems.partner.list.shop",
                link: "/partner/level/shop",
                parentId: 500,
                checkRole: true,
                roles: ['top', 'super', 'master', 'agent']
            },
            /*
            {
                id: 507,
                label: "menuitems.partner.list.block",
                link: "/partner/state/suspend",
                parentId: 500,
                checkRole: true,
                roles: ['admin'],
                onlyAdmin: true
            },
            {
                id: 508,
                label: "menuitems.partner.list.unregister",
                link: "/partner/state/unregister",
                parentId: 500,
                checkRole: true,
                roles: ['admin'],
                onlyAdmin: true
            },

             */
        ]
    },
    {
        id: 900,
        label: "menuitems.transactionPartnerMoney.text",
        icon: "",
        link: '/transaction/partner/money'
    },
    {
        id: 300,
        label: "menuitems.users.text", // users
        isTitle: true
    },
    {
        id: 400,
        label: "menuitems.user.text", // 회원 관리
        icon: "",
        link: "/user/state/normal"
    },
    {
        id: 1000,
        label: "menuitems.transactionUserMoney.text",
        icon: "",
        link: '/transaction/user/money'
    },
    {
        id: 700,
        label: "menuitems.transaction.text",
        isTitle: true
    },
    {
        id: 800,
        label: "menuitems.transactionAll.text",
        icon: "",
        link: "/transaction/all"
    },
    {
        id: 1100,
        label: "menuitems.transactionGame.text",
        icon: "",
        link: '/transaction/game'
    },
    {
        id: 1200,
        label: "menuitems.report.text",
        isTitle: true
    },
    {
        id: 1350,
        label: "menuitems.reportMoney.text",
        icon: "",
        link: "/report/money"
    },
    {
        id: 1360,
        label: "menuitems.reportSubMoney.text",
        icon: "",
        link: "/report/sub/money"
    },
    {
        id: 1400,
        label: "menuitems.reportSettlementDay.text",
        icon: "",
        link: "/report/settlement/day"
    },
    {
        id: 1410,
        label: "menuitems.reportSettlementMonth.text",
        icon: "",
        link: "/report/settlement/month"
    },
    {
        id: 1420,
        label: "menuitems.reportActivityLog.text",
        icon: "",
        link: "/report/log/activity",
        checkRole: true,
        roles: ['admin'],
        onlyAdmin: true
    },
    {
        id: 1430,
        label: "menuitems.reportSeamlessLog.text",
        icon: "",
        link: "/report/log/transfer",
        checkRole: true,
        roles: ['admin'],
        onlyAdmin: true
    },
    /*
    {
        id: 1420,
        label: "menuitems.reportSettlementYear.text",
        icon: "",
        link: "/report/settlement/year"
    },

     */
    {
        id: 1700,
        label: "menuitems.setting.text",
        isTitle: true
    },
    {
        id: 1800,
        label: "menuitems.settingApi.text",
        icon: "",
        link: "/setting/api"
    },
    {
        id: 1900,
        label: "menuitems.settingVendor.text",
        icon: "",
        link: "/setting/vendors"
    },
    {
        id: 2000,
        label: "menuitems.settingGame.text",
        icon: "",
        link: "/setting/games"
    },
    {
        id: 2100,
        label: "menuitems.adminSetting.text",
        isTitle: true,
        checkRole: true,
        roles: ['admin'],
        onlyAdmin: true
    },
    {
        id: 1850,
        label: "menuitems.settingCompany.text",
        icon: "",
        link: "/setting/companies",
        checkRole: true,
        roles: ['admin'],
        onlyAdmin: true
    },
    {
        id: 2110,
        label: "menuitems.settingMaintenance.text",
        icon: "",
        link: "/setting/maintenance",
        checkRole: true,
        roles: ['admin'],
        onlyAdmin: true
    },
    {
        id: 2115,
        label: "menuitems.settingNotice.text",
        icon: "",
        link: "/setting/notice"
    },
    {
        id: 2117,
        label: "menuitems.settingUpdate.text",
        icon: "",
        link: "/setting/update"
    },
    {
        id: 2118,
        label: "menuitems.settingVendorUpdate.text",
        icon: "",
        link: "/setting/vendor/update"
    },
    {
        id: 2130,
        label: "menuitems.settingServerLog.text",
        icon: "",
        link: "/setting/log/server",
        checkRole: true,
        roles: ['admin'],
        onlyAdmin: true
    },
    {
        id: 2140,
        label: "menuitems.settingServerStatus.text",
        icon: "",
        link: "/setting/status/server",
        checkRole: true,
        roles: ['admin'],
        onlyAdmin: true
    },
    {
        id: 2200,
        label: "menuitems.apiDocument.text",
        isTitle: true
    },
    /*
    {
        id: 2250,
        label: "menuitems.apiStress.text",
        icon: "bx-cake",
        link: "/api/stress",
        checkRole: true,
        roles: ['admin'],
        onlyAdmin: true
    },

     */
    {
        id: 2300,
        label: "menuitems.apiCommon.text",
        icon: "",
        subItems: [
            {
                id: 2301,
                label: "menuitems.apiTransferIntroduce.text",
                icon: "bx-tone",
                link: "/api/common/introduce",
                parentId: 2300
            },
            {
                id: 2303,
                icon: "bxs-bar-chart-alt-2",
                label: "menuitems.apiTransferVendors.text",
                link: "/api/common/vendors",
                parentId: 2300
            },
            {
                id: 2304,
                label: "menuitems.apiTransferGames.text",
                icon: "bx-aperture",
                link: "/api/common/games",
                parentId: 2300
            },
            {
                id: 2306,
                label: "menuitems.apiTransferPartnerBalance.text",
                icon: "bx-map",
                link: "/api/common/partner/balance",
                parentId: 2300
            },
            {
                id: 2307,
                label: "menuitems.apiTransferTransactions.text",
                icon: "bx-share-alt",
                link: "/api/common/transactions",
                parentId: 2300
            },
            {
                id: 2308,
                label: "menuitems.apiCommonDetailList.text",
                icon: "bx-share-alt",
                link: "/api/common/detail",
                parentId: 2300
            },

            {
                id: 2310,
                label: "menuitems.apiCommonDetail.text",
                icon: "bx-share-alt",
                link: "/api/common/detail/callback",
                parentId: 2300
            },
            {
                id: 2309,
                label: "menuitems.apiTransferTransactionPragmatic.text",
                icon: "bx-share-alt",
                link: "/api/common/transaction/pragmatic",
                parentId: 2300
            },
            {
                id: 2320,
                label: "menuitems.apiTransferTransactionEvolution.text",
                icon: "bx-share-alt",
                link: "/api/common/transaction/evolution",
                parentId: 2300
            },
        ]
    },

    {
        id: 2350,
        label: "menuitems.apiTransfer.text",
        icon: "",
        subItems: [
            {
                id: 2351,
                label: "menuitems.apiTransferIntroduce.text",
                icon: "bx-tone",
                link: "/api/transfer/introduce",
                parentId: 2300
            },

            {
                id: 2302,
                label: "menuitems.apiTransferRegister.text",
                icon: "bxs-eraser",
                link: "/api/transfer/register",
                parentId: 2300
            },
            {
                id: 2305,
                icon: "bx-list-ul",
                label: "menuitems.apiTransferPlay.text",
                link: "/api/transfer/play",
                parentId: 2300
            },
            {
                id: 2307,
                label: "menuitems.apiTransferUserBalance.text",
                icon: "bx-share-alt",
                link: "/api/transfer/user/balance",
                parentId: 2300
            },
            {
                id: 2308,
                label: "menuitems.apiTransferUserBalanceCB.text",
                icon: "bx-share-alt",
                link: "/api/transfer/user/balance/callback",
                parentId: 2300
            },
            {
                id: 2309,
                label: "menuitems.apiTransferDeposit.text",
                icon: "bx-share-alt",
                link: "/api/transfer/deposit",
                parentId: 2300
            },
            {
                id: 2310,
                label: "menuitems.apiTransferWithdraw.text",
                icon: "bx-share-alt",
                link: "/api/transfer/withdraw",
                parentId: 2300
            }
        ]
    },
/*
    {
        id: 2200,
        label: "menuitems.apiSeamless.text",
        isTitle: true
    },

 */
    {
        id: 2400,
        label: "menuitems.apiSeamlessGuide.text",
        icon: "",
        subItems: [
            {
                id: 2401,
                label: "menuitems.apiSeamlessIntroduce.text",
                icon: "bx-tone",
                link: "/api/seamless/introduce",
                parentId: 2400
            },
            {
                id: 2403,
                label: "menuitems.apiSeamlessAuthenticate.text",
                icon: "bxs-eraser",
                link: "/api/seamless/authenticate",
                parentId: 2400
            },
            {
                id: 2404,
                icon: "bx-list-ul",
                label: "menuitems.apiSeamlessBalance.text",
                link: "/api/seamless/balance",
                parentId: 2400
            },
            {
                id: 2405,
                icon: "bx-list-ul",
                label: "menuitems.apiSeamlessBet.text",
                link: "/api/seamless/bet",
                parentId: 2400
            },
            {
                id: 2406,
                icon: "bxs-bar-chart-alt-2",
                label: "menuitems.apiSeamlessResult.text",
                link: "/api/seamless/result",
                parentId: 2400
            },
            {
                id: 2407,
                label: "menuitems.apiSeamlessCancel.text",
                icon: "bx-aperture",
                link: "/api/seamless/cancel",
                parentId: 2400
            },
            /*
            {
                id: 2408,
                label: "menuitems.apiSeamlessTest.text",
                icon: "bx-aperture",
                link: "/api/seamless/check",
                parentId: 2400
            }

             */
        ]
    },
];
